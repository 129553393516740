import React from "react"
import { Link } from "gatsby"
import { withTrans } from "../withTrans"

const EventScrollableCardItem = props => {
  return (
    <Link to={props.item.slug} className="flex mb-4">
      <div className="block overflow-hidden mx-3 lg:mx-7">
        <div>
          <div className="font-futurastd_heavy pt-3 uppercase text-lg hover:text-gray-400">
            {" "}
            {props.item.title}
          </div>
          <div className="bg-title-dashed-bg bg-no-repeat bg-bottom w-full h-1"></div>
          <div className="text-xs pb-6 pt-3 font-futurastd_book">
            {props.item.description}
            <span className="font-futurastd_heavy">
              ...{props.t("label_read_more")}
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default withTrans(EventScrollableCardItem)
