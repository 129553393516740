import Layout from "../components/layout"
import { graphql } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import EventsCardsList from "../components/EventsCardsList"
import { FaDownload } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      staticPages(locales: en, where: { pageIdentifier: "PressPage" }) {
        asset {
          url
        }
      }
      pressPages(locales: $locale) {
        id
        title
        description
        slug
        cardImage(locales: en) {
          url
        }
      }
    }
  }
`

class Press extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pressData: this.props.data.gcms.pressPages,
    }
  }
  render() {
    const { t } = this.props
    return (
      <Layout>
        <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
          <div className="text-center inline-block bg-white">
            <span className="text-2xl">&lt;</span>
            <span className="tracking-title-3 text-base ml-3 mr-2 uppercase">
              {t("title_press")}
            </span>
            <span className="text-2xl">&gt;</span>
          </div>
        </div>

        <div className="font-futurastd_book text-xs uppercase lg:inline-flex lg:justify-center mb-3 mx-3 lg:mx-7 lg:w-full">
          <div className="mx-3">
            <a
              className=" inline-flex"
              href="mailto:info@cimatheque.org"
              target="_blank"
              rel="noreferrer"
            >
              <MdEmail className="inline text-sm" />{" "}
              {t("title_for_media_inquiries")}
            </a>
          </div>
          <div className="mx-3">
            <a
              href={this.props.data.gcms.staticPages[0].asset.url}
              target="_blank"
              rel="noreferrer"
            >
              <FaDownload className="inline " /> {t("title_download_presskit")}
            </a>
          </div>
        </div>
        <EventsCardsList items={this.state.pressData} />
      </Layout>
    )
  }
}

export default withTrans(Press)
