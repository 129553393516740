import React from "react"
import { Link } from "gatsby"
import { withTrans } from "../withTrans"


const CardItem = props => {
  return (
    <Link
      to={props.item.slug}
      className={`${
        props.count % 3 ? `rtl:border-l ltr:border-r border-none lg:border-dashed` : ``
      } flex mb-4  border-black`}
    >
      <div
        className={`${
          props.responsive ? `container flex md:block` : `block`
        } overflow-hidden mx-3 lg:mx-7 border-dashed border-b  border-black`}
      >
        <div className={`${props.responsive ? ` w-2/4 md:w-full  mb-4` : ``} overflow-hidden`}>
          <img
            className="h-full w-full object-contain"
            src={props.item.cardImage.url}
            alt={props.item.title}
          />
        </div>
        <div className={`${props.responsive ? `w-2/4 ml-7 md:ml-0 md:w-full` : ``}`}>
          <div className="font-futurastd_heavy pt-3 uppercase text-lg hover:text-gray-400"> {props.item.title}</div>
          <div className="text-xs pb-6 pt-3 font-futurastd_book">
            {props.item.description.substring(0, 450)}
            <span className="font-futurastd_heavy">...{props.t("label_read_more")}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default withTrans(CardItem)
