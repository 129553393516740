import React from "react"
import CardItem from "./CardItem"
import EventScrollableCardItem from "./EventScrollableCardItem"

export default class EventsCardsList extends React.Component {
  constructor(props) {
    super(props)
    this.getCards = this.getCards.bind(this)
    this.getScrollableCards = this.getScrollableCards.bind(this)
  }
  getCards(items) {
    var cardsArray = []
    var count = 0

    if (items.length < 5) {
      count = items.length
    } else {
      count = 5
    }

    for (let i = 0; i < count; i++) {
      const item = items[i]
      cardsArray.push(<CardItem key={i} item={item} count={i + 1}></CardItem>)
    }

    return cardsArray
  }
  getScrollableCards(items) {
    var cardsArray = []
    if (items < 5) {
      return
    } else {
      for (let i = 6; i < items.length; i++) {
        const item = items[i]
        cardsArray.push(
          <EventScrollableCardItem
            key={i}
            item={item}
            count={i + 1}
            responsive={true}
          ></EventScrollableCardItem>
        )
      }
    }
    return cardsArray
  }
  render() {
    return (
      <div className=" block md:grid md:grid-cols-3">
        {this.getCards(this.props.items)}

        <div className="overflow-auto relative h-slider-height">
          {this.getScrollableCards(this.props.items)}
        </div>
      </div>
    )
  }
}
